<template>
<div>

  <!-- Filters -->
  <hospital-filter
    @refreshData="refreshData"
  />

<b-card>


    

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewSidebarActive" :companyType="companyType" :pageTitle="pageTitle"   :dataSlider="sidebarData"  
    />

    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
     <!-- <b-button
        @click="addNewData"
        variant="outline-primary"
        class="mb-2"
        style="width: 170px;"
      >
        Add {{ pageTitle}}
      </b-button> -->
      

      <b-table responsive :items="items.data" :fields="tableColumns" striped
      hover>

      <template #cell(id)="data">
          {{data.index + 1}}
      </template>


      <template #cell(status)="data">
        <b-badge @click="changeStatus(data.item.id, data.index)" :variant="status[1][data.value]" style="cursor: pointer;">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      <template #cell(created_at)="data" >
          {{data.value | moment('YY-MM-DD HH:mm:ss')}}
      </template>

   

      <template #cell(status)="data">
        <b-badge @click="changeStatus(data.item.id, data.item.status)" :variant="status[1][data.value]" style="cursor: pointer;">
          {{ status[0][data.value] }}
        </b-badge>
      </template>

      
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item @click="editData(data.item)" >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

        </b-dropdown>
      </template>
      

      </b-table>

      <div class="demo-spacing-0">
        <b-pagination
          :value="1"
          
          :total-rows="items.total"
          :per-page="items.per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="(value)=>getData(value)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    

    </b-overlay>
  </b-card>

</div>
</template>


<script>
import { BTable, BCard, BCardText,BAvatar, BOverlay, BBadge, BFormSelect, BDropdownItem, BDropdown, BButton, BPagination } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNew from './AddNew.vue'
// import companyData from './companyData'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { onMounted } from 'vue'
import DataService  from './data/services'
import HospitalFilter from './filter'
export default {
  
  components: {
    BTable,
    BCard,
    BCardText,
    BAvatar,
    BOverlay,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    AddNew,
    BButton,
    ToastificationContent,
    HospitalFilter,
    BPagination
  },
  data() {
    return {
      items: [],
      sidebarData: {},
      isAddNewSidebarActive: false,
      isLoading: true,
      companyType: [],
      pageTitle: "Hospital",
      params:'',
      currentPage: 1,
      tableColumns: [
        { key: 'actions',   },
        { key: 'id', sortable: true },
        { key: 'pharmacy.name', label: 'Pharmacy', sortable: true },
        { key: 'medical_rep.name', label: 'MedicalRep', sortable: true },
        { key: 'medication_writing.name', label: 'Medication Writing', sortable: true },
        { key: 'pharmaceutical.name', label: 'Pharmaceutical', sortable: true },
        { key: 'count', label: 'Count', sortable: true },
        { key: 'created_at', label: 'Created At', sortable: true },
        
      ],
      status: [
        {
          1: 'Actvie', 0: 'Inactive',
        },
        {
          1: 'light-success', 0: 'light-danger', 
        }
      ],
      nameFilter: '',
      
    }
  },

    
  methods: {
    getData(page){
      this.isLoading = true;
      DataService.getAll(this.params, page)
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
        })
    },
    changeStatus(id, status){
      
      this.isLoading = true;
      DataService.update(id, {status: !status})
      .then(response => {
        this.isLoading = false
        this.items = response.data.data
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'successfully',
              icon: 'BellIcon',
              text: response.data.responseDescription,
              variant: 'success',
            },
          })
      })
    },
    addNewData() {
      this.$router.push('/createPharmacy')  
      // this.sidebarData = {}
      // this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewSidebarActive = val
    },
    refreshData(data){
      this.params = `name=${data.name}&regionId=${data.regionId}&status=${data.status}`
      this.getData(this.currentPage)
    },
    refreshNewData(data) {
      this.items = data
    }
    
  },
  mounted() {
    this.getData(this.currentPage)
  },
  
}
</script>