import axios from '@/libs/axios'

class DataService {
  getAll(params, page) {
    return axios.get(`/pharmaciesReport?page=${page}&${params}`);
  }
  get(id) {
    return axios.get(`/pharmaciesReport/${id}`);
  }
  create(data) {
    return axios.post("/pharmaciesReport", data);
  }
  update(id, data) {
    return axios.put(`/pharmaciesReport/${id}`, data);
  }
}
export default new DataService();